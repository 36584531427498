[data-elm-hot="true"] {
  height: inherit;
}

body {
  text-align: center;
  color: #293c4b;
  margin: 0;
  font-family: Source Sans Pro, Trebuchet MS, Lucida Grande, Bitstream Vera Sans, Helvetica Neue, sans-serif;
}

h1 {
  font-size: 30px;
}

img {
  max-width: 200px;
  margin: 20px 0;
}

.rte-main {
  text-align: left;
  -webkit-user-select: text;
  user-select: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  min-height: 100px;
  margin-top: 16px;
}

.rte-main img {
  border: 1px solid #f2f2f2;
  min-width: 20px;
  max-width: 200px;
  min-height: 20px;
}

.rte-hide-caret {
  caret-color: #0000;
}

@supports (-webkit-touch-callout: none) {
  .rte-hide-caret {
    caret-color: auto;
  }
}

.rte-button {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.rte-button.rte-active {
  color: #5890ff;
}

.rte-button.rte-disabled {
  color: #ccc;
  pointer-events: none;
}

.rte-controls {
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 16px;
  padding-bottom: 8px;
  font-size: 14px;
  display: inline-block;
}

.rte-controls-container {
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
}

.editor-container {
  border: 1px solid #f2f2f2;
  padding: 12px 16px 16px;
}

.rte-selected {
  outline: 2px solid #8cf;
}

.rte-main a {
  color: #00f;
}

.rte-main a:hover {
  color: #00008b;
}

.rte-main a:visited {
  color: #8a2be2;
}

.rte-main blockquote {
  border-left: 2px solid #ccc;
  padding-left: 16px;
}

.rte-main pre {
  white-space: pre-wrap;
}

.rte-main p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.rte-modal-container {
  z-index: 200;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 0;
  padding: 10px;
  transition: transform .2s ease-out;
  position: fixed;
  overflow: hidden;
}

.rte-modal-container h3 {
  text-align: center;
}

.rte-modal--top .rte-modal-container {
  border-width: 0 0 1px;
  width: 100%;
  top: 0;
  left: 0;
  transform: translate(0%, -100%);
}

.rte-modal--top.rte-modal-isOpen .rte-modal-container {
  transform: translate(0%);
}

.rte-modal--right .rte-modal-container {
  border-width: 0 0 0 1px;
  height: 100%;
  top: 0;
  right: 0;
  transform: translate(100%);
}

.rte-modal--right.rte-modal-isOpen .rte-modal-container {
  transform: translate(0%);
}

.rte-modal--bottom .rte-modal-container {
  border-width: 1px 0 0;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
}

.rte-modal--bottom.rte-modal-isOpen .rte-modal-container {
  transform: translate(0%);
}

.rte-modal--left .rte-modal-container {
  border-width: 0 1px 0 0;
  height: 100%;
  top: 0;
  left: 0;
  transform: translate(-100%);
}

.rte-modal--left.rte-modal-isOpen .rte-modal-container {
  transform: translate(0%);
}

.rte-modal-backdrop {
  z-index: 100;
  background: none;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.rte-modal-isOpen .rte-modal-backdrop {
  background: #0006;
  display: block;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background: none;
}
/*# sourceMappingURL=index.4a0cec39.css.map */
