/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot='true'] {
    height: inherit;
}

body {
    font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    text-align: center;
    color: #293c4b;
}

h1 {
    font-size: 30px;
}

img {
    margin: 20px 0;
    max-width: 200px;
}

.rte-main {
    margin-top: 16px;
    min-height: 100px;
    text-align: left;
    outline: none;
    user-select: text;
    -webkit-user-select: text;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.rte-main img {
    min-width: 20px;
    min-height: 20px;
    max-width: 200px;
    border: 1px solid #f2f2f2;
}

.rte-hide-caret {
    caret-color: transparent;
}

/*
 * Workaround for https://github.com/mweiss/elm-rte-toolkit/issues/16, iOS has issues
 * changing caret color on elements that are already selected.
 */
@supports (-webkit-touch-callout: none) {
    .rte-hide-caret {
        caret-color: auto;
    }
}

.rte-button {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.rte-button.rte-active {
    color: #5890ff;
}

.rte-button.rte-disabled {
    color: #ccc;
    pointer-events: none;
}

.rte-controls {
    text-align: left;
    font-size: 14px;
    padding-bottom: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    margin-left: 16px;
}

.rte-controls-container {
    border-bottom: 1px solid #f2f2f2;
    text-align: left;
}

.editor-container {
    padding: 12px 16px 16px 16px;
    border: 1px solid #f2f2f2;
}

.rte-selected {
    outline: 2px solid #8cf;
}

.rte-main a {
    color: blue;
}

.rte-main a:hover {
    color: darkblue;
}

.rte-main a:visited {
    color: blueviolet;
}

.rte-main blockquote {
    padding-left: 16px;
    border-left: 2px solid #ccc;
}

.rte-main pre {
    white-space: pre-wrap;
}

.rte-main p {
    margin-bottom: 1em;
    margin-top: 1em;
}

.rte-modal-container {
    border: 1px solid #333;
    padding: 10px;
    background-color: white;
    border-radius: 0 0 0 0;
    position: fixed;
    overflow: hidden;
    transition: transform 0.2s ease-out;
    z-index: 200;
}

.rte-modal-container h3 {
    text-align: center;
}

.rte-modal--top .rte-modal-container {
    top: 0;
    left: 0;
    width: 100%;
    border-width: 0 0 1px 0;
    transform: translate(0%, -100%);
}

.rte-modal--top.rte-modal-isOpen .rte-modal-container {
    transform: translate(0%, 0%);
}

.rte-modal--right .rte-modal-container {
    top: 0;
    right: 0;
    height: 100%;
    border-width: 0 0 0 1px;
    transform: translate(100%, 0%);
}

.rte-modal--right.rte-modal-isOpen .rte-modal-container {
    transform: translate(0%, 0%);
}

.rte-modal--bottom .rte-modal-container {
    bottom: 0;
    left: 0;
    width: 100%;
    border-width: 1px 0 0 0;
    transform: translate(0%, 100%);
}

.rte-modal--bottom.rte-modal-isOpen .rte-modal-container {
    transform: translate(0%, 0%);
}

.rte-modal--left .rte-modal-container {
    top: 0;
    left: 0;
    height: 100%;
    border-width: 0 1px 0 0;
    transform: translate(-100%, 0%);
}

.rte-modal--left.rte-modal-isOpen .rte-modal-container {
    transform: translate(0%, 0%);
}

.rte-modal-backdrop {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 100;
    display: none;
}

.rte-modal-isOpen .rte-modal-backdrop {
    background: rgba(0, 0, 0, 0.4);
    display: block;
}

::-webkit-calendar-picker-indicator {
    background: none;
    cursor: pointer;
}
